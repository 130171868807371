<template>
    <template v-if="item.editor && item.editor === 'datetime'">
     <!--   <DatePicker v-model="item.value" format="Short Date"  :clearable="false" v-if="!item.isRange">
            <button class="btn btn-link p-0" v-if="!item.value"> {{$t('Choose')}}...</button>
            <button class="btn btn-link text-dark p-0 " v-if="item.value">{{Utils.formatDate(item.value,"Short Date")}}</button>
        </DatePicker>
        <DatePicker v-model="item.rangeValue0" format="Short Date"  :clearable="false" v-if="item.isRange">
            (<button class="btn btn-link p-0" v-if="!item.rangeValue0"> {{$t('From')}}...</button>
            <button class="btn btn-link text-dark p-0" v-if="item.rangeValue0">{{Utils.formatDate(item.rangeValue0,"Short Date")}}</button>, 
        </DatePicker>
         <DatePicker v-model="item.rangeValue1" format="Short Date"  :clearable="false" v-if="item.isRange">
             <button class="btn btn-link p-0" v-if="!item.rangeValue1"> {{$t('To')}}...</button>
            <button class="btn btn-link text-dark p-0" v-if="item.rangeValue1">{{Utils.formatDate(item.rangeValue1,"Short Date")}}</button>)
        </DatePicker>-->
         <DatePicker v-model="item.value" format="Short Date" placeholder="Select..." input-class-name="rounded-0 form-control form-control-sm"  v-if="!item.isRange"/>
         <DatePicker v-model="item.rangeValue0" format="Short Date" placeholder="Select..." input-class-name="rounded-0 form-control form-control-sm"  :clearable="false" v-if="item.isRange"/>
         <DatePicker v-model="item.rangeValue1" format="Short Date" placeholder="Select..." input-class-name="rounded-0 form-control form-control-sm"  :clearable="false" v-if="item.isRange"/>
    </template>
    <template v-else-if="item.editor && item.editor === 'textbox'">
        <input :type="item.valueType == 'number'?'number':'text'" 
            class="form-control form-control-sm rounded-0" 
            placeholder="Type..." v-model="item.value"/>
        <!--  <o-dropdown placement="bottom-end"  ref="dropdown">
            <template #default="scope">
                <button class="btn btn-link p-0"  :ref="scope.target" @click="scope.open" :class="{'text-dark':item.value}">
                    <template v-if="!item.value">
                        {{$t('Type')}}...
                    </template>
                    <template v-else-if="item.value && item.valueType === 'number'">
                        {{item.value}}
                    </template>
                    <template v-else>
                        '{{item.value}}'
                    </template>
                </button>
            </template>

            <template #dropdown="scope">
                <div class="card shadow rounded-0 p-2 " :ref="scope.container">
                    <input :type="item.valueType == 'number'?'number':'text'" v-focus class="form-control form-control-sm" v-model="item.value"/>
                </div>
            </template>
        </o-dropdown>-->
    
    </template>
    <template v-else-if="item.editor && item.editor === 'bit'">
      <!--  <div>
            <select class="form-select border-0 text-primary py-0" placeholder="Select.." v-model="item.value">
                <option value="true">True</option>
                <option value="false">False</option>
            </select>
        </div>-->
        <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="item.value" />
        </div>
    </template>
    <template v-else-if="item.isLookup">
        <Lookups :item="item" />
    </template>
    <template v-else-if="item.valueType == 'number'">
       {{item.value}}
    </template>
    <template v-else-if="item.value">
       '{{item.value}}'
    </template>
    <template v-else>
    {{item}}
    </template>
</template>
<script setup>
    import DatePicker from 'o365.vue.components.DatePicker.vue';
    import Lookups from 'o365.vue.modules.FilterTemplate.Lookups.vue';
    import Utils from 'o365.modules.utils.js';
    import ODropdown from 'o365.vue.components.DropDown.vue';
    const props = defineProps({
        item:Object
    });

    const setEmptyClass = (item) =>{
        return item.value?"":"empty";
    }

</script>
<style scoped>
    .btn-link{
        text-decoration:none;
    }
    .form-control{
        width:100px;
    }
    .dp__main{
        width:unset!important;
    }

 
</style>